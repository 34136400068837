import { render, staticRenderFns } from "./BlogRevPub.vue?vue&type=template&id=7259da88&scoped=true&"
import script from "./BlogRevPub.vue?vue&type=script&lang=js&"
export * from "./BlogRevPub.vue?vue&type=script&lang=js&"
import style0 from "./BlogRevPub.vue?vue&type=style&index=0&id=7259da88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7259da88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VIcon,VRow,VSheet,VSkeletonLoader})
